import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IoClose } from 'react-icons/io5';
import './DescriptionModal.css'; 
 

const DescriptionModal = (props) => {
  const { image, name, position, description, modal, closeModal } = props;

  // Prevent error if description is undefined or null
  const descriptionLines = description ? description.split('\n') : [];
 
  return (
    <Modal
      open={modal}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      hideBackdrop={true}
      style={{ background: '#72ABFF' }}
      onClick={closeModal}
    >
      <Box onClick={(e) => e.stopPropagation()} className="mymodal-content">
        <div className='modal-card'>
          <div className="modal-lottieholder">
            <lottie-player
              id="lottie-animation"
              className="modal-lottie-animation"
              src={image}
              background="transparent"
              speed="1"
              loop
              autoplay
            ></lottie-player>
          </div>
          <div className='modal-description'>
            <p className="name">{name}</p>
            <p className="position">{position}</p>
            {descriptionLines.map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </div>
          <div className='close-button-holder'>
            <div className="close-button bg-color-blue" onClick={closeModal}>
              <IoClose size={"3vmin"} onClick={closeModal} color="white" />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default DescriptionModal;
