
import "../../pages/home/containers/HomeHero.css"
import React, { useEffect, useRef, useState } from 'react';

const SplineHome = () => {
    // const [showHandIcon, setShowHandIcon] = useState(true);
    // const splineRef = useRef();
    // const [isMobile, setIsMobile] = useState(false);
    // const [viewportWidth, setViewportWidth] = useState(window.innerWidth);




    // function onLoad(splineApp) {
    //     splineRef.current = splineApp;
    // }

    // const handleInteraction = () => {
    //     console.log('Spline interaction detected');
    //     window.postMessage('spline-interaction', '*');
    //     setShowHandIcon(false);
    // };


    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = "https://unpkg.com/@splinetool/viewer@1.9.18/build/spline-viewer.js";
    //     script.type = "module";
    //     document.head.appendChild(script);

    //     return () => {
    //         document.head.removeChild(script);
    //     };
    // }, []);

    // useEffect(() => {
    //     const updateWidth = () => {
    //         setViewportWidth(window.innerWidth);
    //     };

    //     window.addEventListener('resize', updateWidth);


    //     const handleMessage = (event) => {
    //         if (event.data === 'spline-interaction') {
    //             handleInteraction();
    //         }
    //     };
    //     const checkScreenSize = () => {
    //         setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    //     };

    //     // Initial check
    //     checkScreenSize();

    //     // Add event listener to update on resize
    //     window.addEventListener('resize', checkScreenSize);
    //     window.addEventListener('message', handleMessage);


    //     return () => {
    //         window.removeEventListener('resize', updateWidth);
    //         window.removeEventListener('message', handleMessage);
    //         window.removeEventListener('resize', checkScreenSize);
    //     };
    // }, []);


    // const viewerStyle = {
    //     width: viewportWidth <= 480 ? "479px" : viewportWidth <= 1199 ? "1198px" : "1210px",
    //     height: viewportWidth <= 480 ? "300px" : "1210px",  // You can adjust the height as needed for different breakpoints
    //     // backgroundColor: "#acb7f5"
    // };


    return (

        // <spline-viewer 
        //     url="https://prod.spline.design/2oeNIQfUfAoLqljr/scene.splinecode"
        //     width="100%"
        //     height="100%"
        //     onLoad={onLoad}
        //     onMouseDown={handleInteraction}
        //     onTouchStart={handleInteraction}
        //     style={{ ...viewerStyle,}}
        // />
        // </div >
        <div className="spline-iframe-container">
            <iframe
                title="External Iframe"
                src="https://my.spline.design/testlookatwagzak-fa4f2d22708e7678e7891a051aa2a2c4/"
                width="100%"
                height="100%"
                className="spline-iframe"
            />
        </div>



    );
};

export default SplineHome;

