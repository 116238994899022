import './ButtonPanel.css';
import React, { useEffect, useState, userRef } from 'react';
import { logEvent } from 'firebase/analytics';
import { gsap } from 'gsap';

function ButtonPanel({ analytics }) {
  return (
    <>
      <div className="button-panel">
        <div className="btn-group dropup d-flex align-items-center text-center justify-content-center">
          <button
            type="button"
            className="btn dropdown-toggle border-none"
            style={{ border: 'none' }}
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="/images/icons/pub_img_app_download.svg"
              alt="app download buttons"
              className="menu-mb"
            />
          </button>
          <ul className="dropdown-menu text-center">
            <li className="text-center">
              <a
                className="dropdown-item custom-dropdown-link"
                href="https://apps.apple.com/kr/app/wagzak/id1672955501"
                onClick={() => {
                  logEvent(analytics, `Download_AppleAPP`);
                }}
              >
                <img
                  src="/images/icons/apple_btn.svg"
                  alt="apple download"
                  className="app-downlaod-btns"
                />
              </a>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li className="text-center">
              <a
                className="dropdown-item"
                href="https://play.google.com/store/apps/details?id=com.circuscompany.Wagzak"
                onClick={() => {
                  logEvent(analytics, `Download_AndroidAPP`);
                }}
              >
                <img
                  src="/images/icons/android_btn.svg"
                  alt="android download"
                  className="app-downlaod-btns"
                />
              </a>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li className="text-center">
              <a
                className="dropdown-item"
                href="https://app.wagzak.io"
                onClick={() => {
                  logEvent(analytics, `Download_QR_CodeAPP`);
                }}
              >
                <img
                  src="/images/icons/app_wagzak.png"
                  className="img-fluid max-width-buttons app-downlaod-btns"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* )} */}
    </>
  );
}

export default ButtonPanel;
